<template>
    <div>
        <div v-if="authUserPermission['abc-evaluation-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">{{ tt('abc_evaluation') }}</h3>
                            </div>
                            <div class="col-5">
                                <base-button class="float-right" size="sm" @click="generate" :disabled="table.onLoad">{{ tt('generate') }}<span v-if="table.onLoad"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="table-responsive header-fixed-multiple customTableInputB4" style="height: 500px">
                            <table class="table table-bordered">
                                <thead class="text-center thead-light">
                                    <tr>
                                        <th rowspan="2">{{ tt('material_number')}}</th>
                                        <th rowspan="2" width="400px">{{ tt('description')}}</th>
                                        <th rowspan="2">{{ tt('uom')}}</th>
                                        <th rowspan="2">{{ tt('current_stock')}}</th>
                                        <th rowspan="2">{{ tt('last_inventory_value')}}</th>
                                        <th rowspan="2">{{ tt('mrp_type') }}</th>
                                        <th :colspan="years.length">{{ tt('gi') }}</th>
                                        <th rowspan="2">{{ tt('total_gi') }}</th>
                                        <th rowspan="2">{{ tt('old')}}</th>
                                        <th rowspan="2" style="width:100px">{{ tt('new') }}</th>
                                    </tr>
                                    <tr>
                                        <th v-for="(year, $index) in years" :key="$index">{{ year }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, $index) in table.data" :key="$index">
                                        <td>{{ row.material_number }}</td>
                                        <td>{{ row.short_description}}</td>
                                        <td>{{ row.uom_code}}</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>{{ row.mtype_code }} - {{ row.mtype_description }}</td>    
                                        <template>
                                            <td  class="text-center" v-for="(gi, $indexGI) in JSON.parse(row.value_gi)" :key="$indexGI">{{ gi.count }}</td>
                                        </template>
                                        <td class="text-center" >{{ row.total_gi }}</td>   
                                        <td class="text-center" :style="{backgroundColor: !!row.updated_by ? '#7A86B6' : 'white',color: !!row.updated_by ? 'white' : 'black'}">{{ row.old_evaluation }}</td>    
                                        <td>
                                            <select type="text" style="border:none;width:100%;" @change="update(row.new_evaluation, row.material_number,row.mcr_item_code, $index)" v-model="row.new_evaluation" :style="{backgroundColor: !!row.updated_by ? '#7A86B6' : 'white',color: !!row.updated_by ? 'white' : 'black'}">
                                                <option label="A" value="A"></option>
                                                <option label="B" value="B"></option>
                                                <option label="C" value="C"></option>
                                                <option label="E" value="E"></option>
                                            </select>
                                        </td>    
                                        <!-- <td  v-for="evaluation in row.evaluation">{{ evaluation.count }}</td>
                                        <td>{{ row.total_gi}} </td>
                                        <td :class="{'bg-primary text-white': row.old_abc_indicator != row.new_abc_indicator}" class="text-center"><input type="text" v-model="row.old_abc_indicator" class="form-control form-control-sm shadow-none border-0 text-center" :class="{'bg-white' : row.new_abc_indicator == row.old_abc_indicator ,'text-white': row.new_abc_indicator != row.old_abc_indicator}" :style="{backgroundColor:row.new_abc_indicator != row.old_abc_indicator ? '#7A86B6' : 'white'}" @blur="update()" disabled></td>
                                        <td :class="{'bg-primary': row.old_abc_indicator != row.new_abc_indicator}"><input type="text" v-model="row.new_abc_indicator" class="form-control form-control-sm shadow-none border-0 text-center" @blur="update()" :style="{backgroundColor:row.new_abc_indicator != row.old_abc_indicator ? '#7A86B6' : 'white', color: !row.adjust ? '#f1ff33' : 'white'}"></td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <infinite-loading @infinite="infiniteHandler"></infinite-loading>
                        </div>
                    </div>
                    <div class="card-footer pb-0">                            
                        <span class="float-right mt-1 mb-3">
                            {{ tt('page_info', {size: table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else></noaccess>
    </div>
</template>

<script>
import {mapState} from 'vuex'
  import Api from '@/helpers/api';
  import abcEvaluationService from '@/services/abcEvaluation/abcEvaluation.service';
    export default {
        data() {
            return {
                table:{
                    data:[],
                    onLoad:false,
                    page:1,
                    loadTimeout:null,
                    last_page:0,
                },
                years:['Y1','Y2','Y3','Y4','Y5'],
            }
        },
         computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        created(){
            this.get();
        },
        methods: {
            get(){
                let context = this; 
                Api(context, abcEvaluationService.get(this.table.page)).onSuccess((response) =>{
                    context.table.data         = response.data.data.data
                    context.table.last_page    = response.data.data.last_page
                    context.table.total        = response.data.data.total
                    context.table.current_page = response.data.data.current_page
                    context.table.per_page     = response.data.data.per_page
                }).onError((e) => {
                    context.table.data = []
                }).onFinish(() =>{
                    context.table.page += 1
                }).call()
            },
            infiniteHandler($state){
                let context = this;
 
                if(context.table.page > context.last_page){
                    $state.complete()
                    return;
                }
                setTimeout(() =>{
                    Api(context, abcEvaluationService.get(context.table.page)).onSuccess((response) =>{
                        context.table.data.push(...response.data.data.data)
                        context.table.last_page    = response.data.data.last_page
                        context.table.current_page = response.data.data.current_page
                        context.table.per_page     = response.data.data.per_page
                        context.table.total        = response.data.data.total
                        $state.loaded()
                    }).onError((e) => {
                        $state.complete()
                     }).onFinish(() => {
                         context.table.page += 1
                     }).call()
                },300)
            },
            update(value, material_number, mcr_item_code, key){
                let data = {
                    new_evaluation : value,
                    material_number: material_number,
                    mcr_item_code: mcr_item_code
                }
                let context = this; 
                Api(context, abcEvaluationService.update(data)).onSuccess((response) =>{
                    context.table.data[key].updated_by = response.data.data
                }).onError((e) => {
                    context.table.data[key].updated_by = null
                }).call()
            },
            generate(){
                let context = this; 
                this.table.onLoad = true;
                Api(context, abcEvaluationService.generate()).onSuccess((response) =>{
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: "bottom",
						horizontalAlign: "left"
                    });
                    context.table.page = 0;
                    context.get()
                }).onError((e) => {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'danger',
                        verticalAlign: "bottom",
						horizontalAlign: "left"
                    });
                }).onFinish(() => {
                     context.table.onLoad = false;
                }).call()
            }
        },
    };
</script>